@charset "UTF-8";

@media (prefers-color-scheme: dark) {
  :root:has(header select option[value="auto"]:checked) {
    color-scheme: dark;
    --fore-color: rgb(201, 202, 192);
    --back-color: rgb(13, 17, 23);
    --btn-back-color: rgb(33, 38, 45);
    --btn-hover-back-color: rgb(48, 54, 61);
    --btn-active-back-color: rgb(40, 46, 51);
    --btn-disabled-fore-color: rgb(56, 79, 88);
    --btn-disabled-back-color: rgb(33, 38, 45);
  }
}

:root:has(header select option[value="dark"]:checked) {
  color-scheme: dark;
  --fore-color: rgb(201, 202, 192);
  --back-color: rgb(13, 17, 23);
  --btn-back-color: rgb(33, 38, 45);
  --btn-hover-back-color: rgb(48, 54, 61);
  --btn-active-back-color: rgb(40, 46, 51);
  --btn-disabled-fore-color: rgb(56, 79, 88);
  --btn-disabled-back-color: rgb(33, 38, 45);
}

* {
  box-sizing: border-box;
  margin: 0;
  border-color: var(--border-color, hsl(210, 18%, 87%));
  font-family: inherit;
  color: inherit;
  background-color: inherit;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: large;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  color: var(--fore-color, rgb(0, 0, 0));
  background-color: var(--back-color, rgb(255, 255, 255));
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1rem;
}

h4 {
  font-size: 0.75rem;
}

hr {
  height: 0;
  box-sizing: content-box;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  margin: 1rem 0;
  border-top: 0;
}

a:has(h1, h2, h3, h4, h5, h6) {
  text-decoration: none;
}

a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4,
a:hover h5,
a:hover h6 {
  filter: brightness(80%);
}

a:hover:not(h1, h2, h3, h4, h5, h6) {
  filter: brightness(120%);
}

input[type="button"],
input[type="submit"],
button {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
  background-color: var(--btn-back-color, rgb(246, 248, 250));
}

input[type="button"]:hover:not(:disabled),
input[type="submit"]:hover:not(:disabled),
button:hover:not(:disabled) {
  background-color: var(--btn-hover-back-color, rgb(243, 244, 246));
}

input[type="button"]:active,
input[type="submit"]:active,
button:active {
  background-color: var(--btn-active-back-color, rgb(240, 241, 244));
}

input[type="button"]:disabled,
input[type="submit"]:disabled,
button:disabled {
  cursor: unset;
  color: var(--btn-disabled-fore-color, rgb(140, 149, 159));
  background-color: var(--btn-disabled-back-color, rgb(246, 248, 250));
}

input[type="text"],
input[type="number"],
input[type="url"],
input[type="file"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
}

input[type="file"] {
  cursor: pointer;
}

input[type="checkbox"] {
  cursor: pointer;
}

label:not([for=""]) {
  cursor: pointer;
}

select {
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

section>*:not(:last-child) {
  margin-bottom: 0.5rem;
}

header {
  color: var(--header-fore-color, rgb(190, 191, 193));
  background-color: var(--header-back-color, rgb(36, 41, 47));
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  >a {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    img[alt="favicon"] {
      height: 1.5rem;
      width: auto;
    }

    h1 {
      color: var(--header-title-fore-color, rgb(255, 255, 255));
      word-break: break-all;
    }
  }

  >div {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;

    select {
      color: var(--fore-color, rgb(0, 0, 0));
      background-color: var(--back-color, rgb(255, 255, 255));
    }
  }
}

main {
  width: 100%;
  max-width: 768px;
  padding: 0.5rem;
}